import * as AccessCodes from './access_codes';
import * as Adjust from './adjust';
import * as Account from './account';
import * as AssignedStarts from './assigned_starts';
import * as Blackouts from './institutions/blackouts';
import * as Cancellations from './cancellations';
import * as Escalations from './escalations';
import * as Exams from './exams';
import * as FlightPath from './flight_path';
import * as Fulfillment from './fulfillment';
import * as Global from './global';
import * as Graphs from './graphs';
import * as IncidentReportCenter from './incident_report_center';
import * as Procedures from './procedures';
import * as Institutions from './institutions';
import * as Iterations from './institutions/iterations';
import * as IterationForms from './institutions/iterations/form';
import * as LocationLoads from './location_loads';
import * as MessageTemplates from './institutions/message_templates';
import * as Lmi from './lmi';
import * as Orders from './orders';
import * as Ptc from './ptc';
import * as Refunds from './refunds';
import * as ReleaseNotes from './release_notes';
import * as Reports from './reports';
import * as Registrations from './registrations';
import * as Reservations from './reservations';
import * as Shared from './shared';
import * as Schedules from './schedules';
import * as Settings from './settings';
import * as SecureBrowser from './secure_browser';
import * as GuardianExtension from '././guardian_extension';
import * as Students from './students';
import * as SystemMetrics from './system_metrics';
import * as TermsOfService from './terms_of_service';
import * as Throttles from './throttles';
import * as Users from './users';

class Dispatcher {
  constructor() {
    this.pageName = document.body.dataset.page;
  }

  preRoute() {
    new Global.Translations().init();
    new Global.AppNavigation().init();
    new Global.AssessmentNotesWidget().init();
    new Global.BootstrapCustomFileInput();
    new Global.BootstrapPopover();
    new Global.BootstrapTooltip();
    new Global.DismissableAlerts().init();
    new Global.Clippy().init();
    new Global.SelectTags();
    new Global.WalkMe().init();
    new Global.LiveChat().init();
    new Global.BoldChatEventLogger().init();
    new Global.GenericSelect2().init();
    new Global.QuickLauncher().init();
    if (document.getElementById('skip-to-content')) {
      document
        .getElementById('skip-to-content')
        .addEventListener('click', (e) => {
          e.preventDefault();
          window.location.href = '#content';
        });
    }
  }

  route() {
    switch (this.pageName) {
      case 'access_code_shipments:index':
        new AccessCodes.AccessCodeShipments().execute();
        break;
      case 'access_code_shipments:new':
        new AccessCodes.AccessCodeShipments().execute();
        break;
      case 'access_code_shipments:edit':
        new AccessCodes.AccessCodeShipments().execute();
        break;
      case 'access_codes:index':
        new AccessCodes.AccessCodeReactivator().init();
        break;
      case 'access_codes:new':
        new AccessCodes.AccessCodeCreator().init();
        break;
      case 'access_code_shipments:print':
        new Shared.PrintReceipt().init();
        break;
      case 'accounts:edit':
        new Users.AddressRequirementCheck().init();
        new Students.EnrollmentSelect2();
        new Registrations.InstitutionSelector().init();
        new Users.GoogleRequirementCheck().init();
        new Users.WebcamInitializer().init();
        new Institutions.AdditionalEnrollmentField().init();
        new Institutions.DisplayDob().init();
        break;
      case 'accounts:notifications':
        new Account.Notifications().init();
        break;
      case 'adjust:fulfillments:index':
        new Reports.DatePicker().init();
        new Adjust.CheckAllCheckBox().init();
        break;
      case 'authentications:new':
      case 'authentications:edit':
        new Shared.Summernote('.html-editor').init();
        break;
      case 'escalations:reservations:index':
        new Escalations.Escalator().init();
        break;
      case 'exams:select':
        new Exams.ExamSelect().init();
        break;
      case 'external:smarter_services:reservations:videos:index':
        new Fulfillment.TurbolinksEventsHandler().init();
        new Fulfillment.VideoPlayerRenderer().init();
        break;
      case 'exam_soft:fulfillments:show':
        new Fulfillment.VideoPlayerRenderer().init();
        new Fulfillment.LocalTime().init();
        new Fulfillment.MediaPlayerRenderer(true).init();
        new Fulfillment.ImageZoom().init();
        break;
      case 'fulfillments:show':
        new Fulfillment.TurbolinksEventsHandler().init();
        new Cancellations.CancellationModal().init();
        new FlightPath.ExamUnlocker().init();
        new FlightPath.ReEnableCancelledFlightPathLink().init();
        new FlightPath.TransferButton().init();
        new FlightPath.NextButton().init();
        new FlightPath.PreviousButton().init();
        new FlightPath.FlightPathNav().init();
        new FlightPath.IdConfirmation().init();
        new FlightPath.CompleteCheckbox().init();
        new FlightPath.WatcherPopout().init();
        new FlightPath.TouchPointButton().init();
        new FlightPath.PictureConfirmation().init();
        new FlightPath.UploadMedia().init();
        new FlightPath.Initializer();
        new Fulfillment.AbortButton().init();
        new Fulfillment.EscalateButton().init();
        new Fulfillment.RetryButton().init();
        new Fulfillment.ExamPanel().init();
        new Fulfillment.ExamDropdown().init();
        new Fulfillment.FlagNotes().init();
        new Fulfillment.ImageZoom().init();
        new Fulfillment.MediaObject().init();
        new Fulfillment.MediaPlayerRenderer().init();
        new Fulfillment.PictureDragDrop().init();
        new Fulfillment.StartUpdater().init();
        new Fulfillment.UnlockAuthentication().init();
        new Fulfillment.UnlockExam().init();
        new Fulfillment.LaunchExam().init();
        new Fulfillment.VideoPlayerRenderer().init();
        new Fulfillment.WatchingButtons().init();
        new Fulfillment.GuardianExtensionBrowserSwitchButton().init();
        new Shared.CollapseButton();
        new Shared.ConfirmClearNoShows().init();
        new Fulfillment.AadhaarVerificationStatus().init();
        break;
      case 'fulfillments:flight_path':
        new FlightPath.ExamUnlocker().init();
        new FlightPath.ReEnableCancelledFlightPathLink().init();
        new FlightPath.TransferButton().init();
        new FlightPath.NextButton().init();
        new FlightPath.PreviousButton().init();
        new FlightPath.FlightPathNav().init();
        new FlightPath.IdConfirmation().init();
        new FlightPath.CompleteCheckbox().init();
        new FlightPath.WatcherPopout().init();
        new FlightPath.TouchPointButton().init();
        new FlightPath.PictureConfirmation().init();
        new FlightPath.UploadMedia().init();
        new FlightPath.Initializer();
        break;
      case 'incident_report_center:incident_reports:edit':
        new IncidentReportCenter.CompleteIncidentButton().init();
        new IncidentReportCenter.CreateExternalNoteForm().init();
        new IncidentReportCenter.CreateInternalNoteForm().init();
        new IncidentReportCenter.DestroyExternalReviewNoteButton().init();
        new IncidentReportCenter.DestroyInternalReviewNoteButton().init();
        new IncidentReportCenter.SubmitButtonToggle().init();
        new IncidentReportCenter.ProcessButton().init();
        new IncidentReportCenter.EditLink().init();
        new Shared.Summernote('.html-editor').init();
        break;
      case 'incident_report_center:incident_reports:show':
        new IncidentReportCenter.CreateExternalNoteForm().init();
        new IncidentReportCenter.CreateInternalNoteForm().init();
        new IncidentReportCenter.DestroyExternalReviewNoteButton().init();
        new IncidentReportCenter.DestroyInternalReviewNoteButton().init();
        break;
      case 'incident_report_center:incident_reports:reviews:new':
      case 'incident_report_center:incident_reports:reviews:edit':
        new IncidentReportCenter.Select2AdminContacts().init();
        new IncidentReportCenter.Select2ExamContacts().init();
        new IncidentReportCenter.Select2ProctorUContacts().init();
        break;
      case 'incident_statuses:new':
      case 'incident_statuses:edit':
        new Shared.Summernote('.html-editor').init();
        break;
      case 'institutions:new':
        new Institutions.FormLegendNavigation().init();
        new Institutions.MaxNoShows().init();
        new Institutions.EnableRockwellVoucher().init();
        new Procedures.ProcedureList().init(Shared);
        new Procedures.SortProcedure().init();
        new Shared.Summernote('.html-editor').init();
        new Shared.Summernote('.fp-html-editor').init(true);
        new Institutions.GeneralSettingsForm().init();
        new Institutions.PresetsConfig().init();
        new Institutions.GuidedLaunchOtherResources().init();
        break;
      case 'institutions:proctor_configs:update':
      case 'institutions:proctor_configs:show':
      case 'institutions:proctoring_config:create_iteration_wizard':
        new Institutions.WizardToast().init();
        break;
      case 'institutions:edit':
        new Institutions.AnnualFee().init();
        new Institutions.AdditionalEnrollmentField().init();
        new Institutions.AutoCancellations().init();
        new Institutions.AutoFulfillments().init();
        new Institutions.FileTransferConfiguration().init();
        new Institutions.FormLegendNavigation().init();
        new Institutions.GeneralSettingsForm().init();
        new Institutions.LocationHandler().init();
        new Institutions.SessionPackagingConfiguration().init();
        new Institutions.PasswordReveal().init();
        new Institutions.TestDeliverySettingsValidator().init();
        new Institutions.TestDriverAction().init();
        new Institutions.PresetsConfig().init();
        new Institutions.MaxNoShows().init();
        new Institutions.EnableRockwellVoucher().init();
        new Institutions.GuidedLaunchOtherResources().init();
        new Procedures.ProcedureList().init(Shared);
        new Procedures.SortProcedure().init();
        new Shared.GlFlightPathProcedures().init();
        new Shared.Summernote('.fp-html-editor').init(true);
        new Shared.Summernote('.html-editor').init();
        break;
      case 'institutions:administrator_dashboards:index':
        new Institutions.AdministratorDashboard().init();
        break;
      case 'institutions:blackouts:new':
        new Blackouts.BlackOutForm().init();
        break;
      case 'institutions:brightspace_integrations:show':
        new Institutions.BrightspaceIntegration().init();
        break;
      case 'institutions:canvas_integrations:new':
        new Institutions.AuthorizeCanvasIntegration().init();
        break;
      case 'institutions:canvas_integrations:show':
        new Institutions.CanvasIntegration().init();
        break;
      case 'institutions:departments:edit':
        new Institutions.GeneralSettingsDepartmentsForm().init();
        new Institutions.PresetsConfig().init();
        new Shared.Summernote('.fp-html-editor').init();
        new Shared.GlFlightPathProcedures().init();
        new Institutions.GuidedLaunchOtherResources().init();
      case 'institutions:departments:new':
        new Institutions.AnnualFee().init();
        new Shared.Summernote('.html-editor').init();
        new Institutions.GeneralSettingsDepartmentsForm().init();
        new Institutions.PresetsConfig().init();
        new Institutions.GuidedLaunchOtherResources().init();
        break;
      case 'institutions:departments:iterations:index':
        new Institutions.IterationCheckboxes().init();
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        break;
      case 'institutions:departments:index':
        new Institutions.InstitutionsStatsWidget().init();
        new Institutions.TableStats().init();
        new Institutions.AnnualFee().init();
        new Shared.Summernote('.html-editor').init();
        break;
      case 'institutions:instructors:edit':
      case 'institutions:instructors:new':
        new Institutions.InstructorSelect2().init();
        break;
      case 'institutions:instructors:index':
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        break;
      case 'institutions:iterations:new':
      case 'institutions:iterations:edit':
      case 'institutions:iterations:clones:new':
      case 'institutions:iterations:clones:edit':
        new Institutions.AnnualFee().init();
        new Procedures.ProcedureList().init();
        new Procedures.SortProcedure().init();
        new IterationForms.AutoExamScheduling().init();
        new IterationForms.Checkboxes().init();
        new IterationForms.Cost().init();
        new IterationForms.Department().init();
        new IterationForms.DepartmentExamUnlockingAutoSubmitting().init();
        new IterationForms.DisableVerificationStepCheckbox().init();
        new IterationForms.ExamWindows().init();
        new IterationForms.IterationType().init();
        new IterationForms.LostFocusAllowedResources().init();
        new IterationForms.MaxAttempts().init();
        new IterationForms.OtherResourcesEditor().init();
        new IterationForms.PresetsConfiguration().init();
        new IterationForms.PtcOption().init();
        new IterationForms.ResourcesCheckboxes().init();
        new IterationForms.NoResourcesCheckbox().init();
        new IterationForms.RetakePolicy().init();
        new IterationForms.Select2Inviter();
        new IterationForms.SelectAllResourcesLink().init();
        new IterationForms.ServiceLine().init();
        new IterationForms.ServiceType().init();
        new IterationForms.SideNavSubmit().init();
        new IterationForms.Split().init();
        new IterationForms.TemplateLoader().init();
        new IterationForms.TestDeliveryIdValidator().init();
        new IterationForms.UpdateCostAndSplit().init();
        new IterationForms.WhiteListUrls().init();
        new IterationForms.WindowEndsAtChecker().init();
        new IterationForms.OverrideCancellationLeadTime().init();
        new IterationForms.OverrideInPersonCancellationLeadTime().init();
        new IterationForms.OverrideRescheduleLeadTime().init();
        new Shared.Summernote('.fp-html-editor').init(true);
        new Shared.Summernote('.html-editor').init();
        new IterationForms.SchedulingPolicy().init();
        new Shared.GlFlightPathProcedures().init();
        new Institutions.GuidedLaunchOtherResources().init();
        break;
      case 'institutions:iterations:index':
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        new Iterations.LimitedEditLink().init();
        break;
      case 'institutions:enrollments:index':
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        break;
      case 'institutions:iterations:message_templates:edit':
        new MessageTemplates.TemplateReverter().init();
        break;
      case 'institutions:iteration_imports:show':
        new Institutions.IterationCheckboxes().init();
        break;
      case 'institutions:iteration_templates:new':
      case 'institutions:iteration_templates:edit':
        new IterationForms.ResourcesCheckboxes().init();
        new IterationForms.NoResourcesCheckbox().init();
        new IterationForms.SelectAllResourcesLink().init();
        new Shared.Summernote('.fp-html-editor').init(true);
        new Shared.Summernote('.html-editor').init();
        break;
      case 'institutions:iterations:show':
        new Iterations.LimitedEditLink().init();
        new Iterations.BIReportGenerator().init();
        new Iterations.InvitationBuilder().init();
        new Iterations.PendingExamAlert().init();
        break;
      case 'institutions:iterations:summary_dashboards:index':
        new Iterations.BISummaryDashboard().init();
        break;
      case 'institutions:message_templates:edit':
        new MessageTemplates.TemplateReverter().init();
        break;
      case 'institutions:show':
        new Institutions.DatePicker().init();
        new Institutions.InstitutionTabsManager().init();
        new Institutions.SettingsTab().init();
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        new Institutions.MessageTemplateDropdown().init();
        new Shared.CollapseButton();
        break;
      case 'institutions:schedules:index':
        new Schedules.DatePicker().init();
        new Graphs.ScheduleGraph().init();
        new Schedules.TimeDisplay().init();
        break;
      case 'institutions:terms:iterations:index':
        new Institutions.IterationCheckboxes().init();
        new IterationForms.UpdateCostAndSplit().init();
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        break;
      case 'institutions:terms:index':
        new Institutions.TableStats().init();
        new Institutions.InstitutionsStatsWidget().init();
        break;
      case 'institutions:users:edit':
      case 'institutions:users:new':
        new Users.AccommodationModifier().init();
        new Users.AddressRequirementCheck().init();
        new Shared.CollapseButton();
        new Users.AssignPasswordCheckbox().init();
        new Users.RoleSelect().init();
        new Users.InstitutionSelect2().init();
        new Users.DepartmentSelect2().init();
        new Users.CampusDropdown().init();
        new Students.EnrollmentSelect2();
        new Users.EnrollmentFields().init();
        new AssignedStarts.AssignedStartsUserEditor().init();
        new Users.LocationSelector().init();
        new Users.WebcamInitializer().init();
        new Shared.Summernote('.html-editor').init();
        break;
      case 'institutions:users:show':
        new Shared.CollapseButton();
        new Shared.ConfirmClearNoShows().init();
        break;
      case 'internal:watcher:popouts:videos:show':
        new Fulfillment.TurbolinksEventsHandler().init();
        //Pass in isWatcher = true to inform the renderer that it is indeed a
        //watcher window and needs some extra functionality
        new FlightPath.UploadMedia().init();
        new Fulfillment.VideoPlayerRenderer().init(true);
        new Fulfillment.MediaPlayerRenderer().init();
        new FlightPath.TouchPointButton().init();
        new FlightPath.ProctorActionsButton().init();
        break;
      case 'internal:global_schedules:index':
        new Graphs.ScheduleGraph().init();
        new Schedules.DatePicker().init();
        break;
      case 'message_templates:edit':
      case 'message_layouts:edit':
        new Shared.Summernote('.html-editor').init();
        break;
      case 'location_loads:index':
        new LocationLoads.LocationActivators().init();
        new LocationLoads.LockableFieldToggle().init();
        new Graphs.ActivityStatsHeatmap().init();
        new Throttles.DatePicker().init();
        break;
      case 'refunds:new':
        new Ptc.LocationSearch().init();
        new Refunds.RefundChoice().init();
        break;
      case 'reschedule:reservations:edit':
        new Ptc.LocationSearch().init();
        new Reservations.DatePicker().init();
        new Reservations.TimePicker().init();
        new Reservations.TimezonePicker().init();
        new Reservations.FindSlotsButton().init();
        new Reservations.NextSlotsButton().init();
        new Reservations.EarliestSlotsButton().init();
        new Reservations.TimezoneWarning().init();
        new Reservations.StudentsReservationSelector().init();
        new Reservations.OverrideRetakePolicy().init();
        new Reservations.MobileView().init();
        break;
      case 'reservations:edit':
        new Ptc.LocationSearch().init();
        new Reservations.DatePicker().init();
        new Reservations.TimePicker().init();
        new Reservations.TimezonePicker().init();
        new Reservations.FindSlotsButton().init();
        new Reservations.NextSlotsButton().init();
        new Reservations.EarliestSlotsButton().init();
        new Reservations.TimezoneWarning().init();
        new Reservations.StudentsReservationSelector().init();
        new Reservations.OverrideRetakePolicy().init();
        new Reservations.MobileView().init();
        break;
      case 'reservations:new':
        new Ptc.LocationSearch().init();
        new Reservations.DatePicker().init();
        new Reservations.TimePicker().init();
        new Reservations.TimezonePicker().init();
        new Students.RetakePolicy().init();
        new Students.TermsOfService().init();
        new Reservations.FindSlotsButton().init();
        new Reservations.NextSlotsButton().init();
        new Reservations.EarliestSlotsButton().init();
        new Reservations.TimezoneWarning().init();
        new Reservations.StudentsReservationSelector().init();
        new Reservations.OverrideRetakePolicy().init();
        new Reservations.MobileView().init();
        break;
      case 'reservations:show':
        new Fulfillment.TurbolinksEventsHandler().init();
        new Fulfillment.VideoPlayerRenderer().init();
        new Fulfillment.MediaPlayerRenderer().init();
        new Fulfillment.AbortButton().init();
        new Fulfillment.MediaObject().init();
        new Fulfillment.ImageZoom().init();
        new Fulfillment.ExamPanel().init();
        new Fulfillment.ExamDropdown().init();
        new Fulfillment.GuardianExtensionBrowserSwitchButton().init();
        new Shared.CollapseButton();
        new Shared.ConfirmClearNoShows().init();
        new Fulfillment.AadhaarVerificationStatus().init();
      case 'release_notes:current':
        new ReleaseNotes.MarkAsRead().init();
        break;
      case 'api_histories:index':
      case 'reports:billing_summaries:index':
      case 'reports:billings:index':
      case 'reports:bluebirds:index':
      case 'reports:cancellations:index':
      case 'reports:exam_endings:index':
      case 'reports:executive_dashboard_stats:index':
      case 'reports:google:reservations:index':
      case 'reports:inactive_exams:index':
      case 'reports:instructor_iterations:index':
      case 'reports:ip_addresses:index':
      case 'reports:logs:index':
      case 'reports:new_exams:index':
      case 'reports:no_checkmarks:index':
      case 'reports:pricing_modifications:index':
      case 'reports:reservation_activities:index':
      case 'reports:surveys:index':
      case 'reports:touch_points:index':
      case 'reports:ucards:index':
      case 'reports:webhook_statuses:index':
      case 'reports:active_iterations:index':
      case 'reports:credit_on_accounts:index':
      case 'reports:no_checkmarks:index':
      case 'reports:active_iterations:index':
      case 'reports:fulfillment_events:index':
      case 'reports:inactive_exams:index':
      case 'reports:user_roles:index':
      case 'reports:reviews:index':
      case 'reports:activity_stats:index':
        new Reports.DatePicker().init();
        new Reports.DownloadModal().init();
        new Reports.ContainerSwitcher().init();
        break;
      case 'reports:lti13_launches:index':
        new Reports.DatePicker().init();
        break;
      case 'reports:performance_insights:index':
        new Reports.DatePicker().init();
        new Reports.DownloadModal().init();
        new Reports.ContainerSwitcher().init();
        new Institutions.AdministratorDashboard().init();
        break;
      case 'registrations:new':
        new Users.WebcamInitializer().init();
        new Registrations.InstitutionSelector().init();
        new Registrations.RegistrationDelay().init();
        new Users.AddressRequirementCheck().init();
        new Students.EnrollmentSelect2();
        break;
      case 'registrations:iterations:new':
        new Registrations.DepartmentSelect().init();
        new Registrations.ResourcesCheckboxes().init();
        new IterationForms.NoResourcesCheckbox().init();
        new IterationForms.SelectAllResourcesLink().init();
        new Shared.Summernote('.html-editor').init(true);
        break;
      case 'sso:saml_users:edit':
        new Users.SamlRegistration().init();
        new Users.AddressRequirementCheck().init();
        break;
      case 'sso:saml_events:index':
        new Reports.DatePicker().init();
        break;
      case 'students:orders:show':
        new Orders.AddAccessCode().init();
        break;
      case 'students:orders:payment':
        new Orders.BillingAddressButton().init();
        break;
      case 'students:orders:print:index':
      case 'students:orders:receipt':
        new Orders.AlignFooterElements().init();
        break;
      case 'incident_report_center:institutions:incident_reports:print:index':
      case 'incident_report_center:incident_reports:print:show':
        new Shared.PrintReceipt().init();
        break;
      case 'students:users:new':
        new Users.AddressRequirementCheck().init();
        new Users.GoogleRequirementCheck().init();
        new Students.EnrollmentSelect2();
        new Registrations.InstitutionSelector().init();
        new Registrations.RegistrationDelay().init();
        new Users.EnrollmentFields().init();
        new Institutions.AdditionalEnrollmentField().init();
        break;
      case 'students:preferences:notifications':
        new Account.Notifications().init();
        break;
      case 'students:reschedule:reservations:edit':
      case 'students:reservations:edit':
      case 'students:reservations:new':
        new Students.RetakePolicy().init();
        new Ptc.LocationSearch().init();
        new Reservations.DatePicker().init();
        new Reservations.TimePicker().init();
        new Reservations.TimezoneWarning().init();
        new Reservations.FindSlotsButton().init();
        new Reservations.NextSlotsButton().init();
        new Reservations.EarliestSlotsButton().init();
        new Reservations.StudentsReservationSelector().init(true);
        new Students.TermsOfService().init();
        new Students.ProctorLanguageSelection().init();
        new Reservations.MobileView().init();
        break;
      case 'students:reservations:index':
        new Students.GuardianBrowserInjection().init();
        new Cancellations.CancellationModal().init();
        new Students.Countdown().init();
        new Students.IframeCheck().init();
        new Students.UserAgentNotifications().init();
        new Students.EventManager().init();
        new Students.UcardScheduleModal().init();
        new Students.EnrollmentFieldsNotifier();
        new Students.DownloadBrowserModal().init();
        new Students.ExamFulfilledModal().init();
        new Students.CustomEligibilityService().init();
        break;
      case 'students:fulfillments:auto':
        new Students.AutoModal().init();
        new SecureBrowser.SecureBrowserDownload().init();
        new GuardianExtension.GuardianExtensionDownload().init();
        break;
      case 'students:exams:select':
        new Exams.ExamSelect().init();
        break;
      case 'students:system_metrics:auto':
        new GuardianExtension.GuardianExtensionDownload().init();
        break;
      case 'students:system_metrics:new':
      case 'system_metrics:index':
      case 'users:system_metrics:new':
        new SystemMetrics.SystemMetrics().init();
        new SecureBrowser.SecureBrowserDownload().init();
        break;
      case 'terms_of_services:edit':
        new TermsOfService.TermsOfServiceDialog().init();
        break;
      case 'throttles:edit':
        new Graphs.ScheduleGraph().init();
        new Graphs.ActivityStatsHeatmap().init();
        new Throttles.DatePicker().init();
        break;
      case 'settings:edit':
        new Settings.Settings().init();
        new Settings.InterventionOnlyHandler().init();
        break;
      case 'use_terms:new':
      case 'use_terms:edit':
        new Shared.Summernote('.html-editor').init();
        break;
      case 'users:edit':
        new Users.WebcamInitializer().init();
        new Students.EnrollmentSelect2();
      case 'users:new':
        new Users.AssignPasswordCheckbox().init();
        new Users.RoleSelect().init();
        new Users.InstitutionSelect2().init();
        new Users.DepartmentSelect2().init();
        new Users.CampusDropdown().init();
        new Students.EnrollmentSelect2();
        new Users.EnrollmentFields().init();
        new Users.AccommodationModifier().init();
        new AssignedStarts.AssignedStartsUserEditor().init();
        new Users.AddressRequirementCheck().init();
        new Users.LocationSelector().init();
        new Users.WebcamInitializer().init();
        new Users.GoogleRequirementCheck().init();
        new Shared.Summernote('.html-editor').init();
        break;
      case 'users:show':
        new Users.TransactionsToggle().init();
        new Shared.ConfirmClearNoShows().init();
        break;
      case 'lmi:extension_lmi:show':
        new Lmi.LmiChat().init();
        break;
    }
  }

  #handleFormErrorMessages() {
    const formErrorMessages = $('#form-error-messages');
    if (formErrorMessages.children().length > 0) {
      formErrorMessages.focus();
    }
  }

  postRoute() {
    this.#handleFormErrorMessages();
  }
}

document.addEventListener('turbolinks:load', () => {
  const dispatcher = new Dispatcher();

  dispatcher.preRoute();
  dispatcher.route();
  dispatcher.postRoute();
});
