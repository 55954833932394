class Select2DataRegion extends React.Component {
  constructor(props) {
    super(props);

    const initialValue = this.getDataRegion() || Object.keys(props.options)[0];
    this.state = {
      selectedValue: initialValue,
    };
    this.props.updateDataRegion(initialValue);
  }

  /**
   * Lifecycle method that is called after the component is mounted.
   * Sets up an event listener on the '#select2-institution' element to handle 'change' events.
   * When the 'change' event is triggered, it calls the updateFilter method with the first key of the options prop.
   */
  componentDidMount() {
    $('#select2-institution').on('change', () => {
      this.updateFilter(Object.keys(this.props.options)[0]);
    });
  }

  getDataRegion() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('data_region');
  }

  handleChange = (event) => {
    this.updateFilter(event.target.value, true);
  };

  /**
   * Updates the filter based on the selected data region.
   * If an option is selected in the '#select2-institution' element, it uses the 'data-data_region' attribute of the selected option.
   * Otherwise, it uses the provided dataRegion parameter.
   *
   * @param {string} dataRegion - The data region to update the filter with.
   * @param {boolean} [showWarning=false] - Whether to show a warning message.
   */
  updateFilter = (dataRegion, showWarning = false) => {
    const selectedOption = $('#select2-institution').find(':selected');
    if (selectedOption.length > 0) {
      const institutionRegion = selectedOption.attr('data-data_region');
      this.setState({
        selectedValue: institutionRegion,
      });
      this.props.updateDataRegion(institutionRegion);
      if (showWarning && institutionRegion !== dataRegion) {
        document.getElementById('data-region-warning-message').style.display =
          'block';
      } else {
        document.getElementById('data-region-warning-message').style.display =
          'none';
      }
    } else {
      this.setState({
        selectedValue: dataRegion,
      });
      this.props.updateDataRegion(dataRegion);
      document.getElementById('data-region-warning-message').style.display =
        'none';
    }
  };

  render() {
    const { options } = this.props;
    const { selectedValue } = this.state;

    return (
      <div>
        <label htmlFor="data_region">Data region</label>
        <select
          name="data_region"
          id="select2-dataregion"
          className="form-control"
          value={selectedValue}
          onChange={this.handleChange}
        >
          {Object.entries(options).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div
          id="data-region-warning-message"
          style={{
            color: 'red',
            marginTop: '10px',
            display: 'none',
          }}
        >
          You are attempting to update the data region value to however it has
          been provided by the system.
        </div>
      </div>
    );
  }
}

/**
 * Default props for the Select2DataRegion component.
 *
 * @property {function} updateDataRegion - Optional function to update the data region.
 * If not provided, it defaults to a no-op function.
 */
Select2DataRegion.defaultProps = {
  updateDataRegion: () => {},
};

export default Select2DataRegion;
