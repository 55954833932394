import React from 'react';

/**
 * @typedef CountdownTime
 * @property {number} days
 * @property {number} hours
 * @property {number} minutes
 * @property {number} [seconds]
 */

/**
 * @typedef {'countdown' | 'start-button' | ''} CountdownState
 */

/**
 * @typedef CountdownProps
 * @property {CountdownTime} time - The countdown time in days, hours, minutes, and seconds.
 * @property {CountdownState} countdownState - The state of the countdown.
 */

/**
 * Countdown component to display the remaining time until the next exam or the
 * start of the current exam.
 *
 * @param {CountdownProps} props
 * @returns {React.ReactElement}
 */
export const Countdown = ({ time, countdownState }) => {
  const isStarted = countdownState === 'start-button';

  if (!countdownState) {
    return <strong className="fs-5">{polyglot.t('exams')}</strong>;
  }

  return (
    <div className="d-flex flex-wrap" data-testid="countdown">
      <div>
        <strong>
          {/* For 'small' and narrower screens */}
          <span className="d-md-none fs-5 mr-1 mr-md-3">
            {isStarted
              ? polyglot.t('your_exam_can_be_started')
              : polyglot.t('next_exam')}
          </span>

          {/* For 'medium' and wider screens */}
          <span className="d-none d-md-block fs-5 mr-1 mr-md-3">
            {isStarted
              ? polyglot.t('your_exam_can_be_started')
              : polyglot.t('your_next_exam_starts_in')}
          </span>
        </strong>
      </div>

      <div data-testid="timeLeft" className="d-flex align-items-center">
        <strong className="fs-5 mr-1">{time.days}</strong>
        <span className="countdown-small-text">{polyglot.t('days')}</span>

        <strong className="fs-5 mr-1">{time.hours}</strong>
        <span className="countdown-small-text">{polyglot.t('hours')}</span>

        <strong className="fs-5 mr-1">{time.minutes}</strong>
        <span className="countdown-small-text">{polyglot.t('minutes')}</span>
      </div>
    </div>
  );
};
