import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import PhoneScanning from '../../../assets/images/second_camera/phone-scanning.svg';
import { SECOND_CAMERA_SCREENS } from './App';
import { useTranslate } from 'react-polyglot';
import useCamAndMicPermissions from '../../hooks/useCamAndMicPermission';

const MediaPermission = (props) => {
  const t = useTranslate();
  const { permissionDenied, requestCamAndMicPermission } =
    useCamAndMicPermissions(() =>
      props.setCurrentStep(SECOND_CAMERA_SCREENS.POSITION_PHONE),
    );

  if (!permissionDenied) {
    return <></>;
  }

  return (
    <>
      <div className="center">
        <div className="col-12">
          <Image src={PhoneScanning} />
        </div>
        <div className="col-12">
          <h6 className="text-center">
            <i className="fa-light fa-camera-web mr-2"></i>
            {t('media_permission.cam_permission_heading')}
          </h6>
          <p>{t('media_permission.grant_camera_permission')}</p>
        </div>
        <div className="col-12 mb-2">
          <Button variant="primary" onClick={requestCamAndMicPermission}>
            {t('media_permission.button.understand')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MediaPermission;
