class MobileView {
  constructor() {
    this.findSlotsButton = document.querySelector('#js-find-slots');
    this.reselectDateButton = document.querySelector('#reselect-date-btn');
    this.dateSelectionCard = document.querySelector('#date-selection-card');
    this.reservationContent = document.querySelector('#reservation-content');

    this.isTimeSelected = false;
    this.isSmallScreen = this.#isSmallScreen();
  }

  init() {
    this.#setupEventListeners();
    this.#syncWithHistory();
  }

  #isSmallScreen() {
    return window.innerWidth < 768;
  }

  #setupEventListeners() {
    this.reselectDateButton?.addEventListener('click', () => {
      this.isTimeSelected = false;
      this.#updateHistory();
      this.#toggleCardOnSmallScreen();
    });

    this.findSlotsButton?.addEventListener('click', () => {
      this.isTimeSelected = true;
      this.#updateHistory();
      this.#toggleCardOnSmallScreen();
    });

    window.addEventListener('resize', () => {
      this.#toggleCardOnSmallScreen();
    });

    window.addEventListener('popstate', (event) => {
      if (event.state) {
        this.isTimeSelected = event.state.isTimeSelected;
        this.#toggleCardOnSmallScreen();
      }
    });
  }

  #toggleCardOnSmallScreen() {
    this.isSmallScreen = this.#isSmallScreen();
    if (this.isSmallScreen) {
      if (this.isTimeSelected) {
        this.dateSelectionCard.classList.add('d-none');
        this.reservationContent.classList.remove('d-none');
        this.reselectDateButton.classList.remove('d-none');
      } else {
        this.dateSelectionCard.classList.remove('d-none');
        this.reservationContent.classList.add('d-none');
        this.reselectDateButton.classList.add('d-none');
      }
    } else {
      this.reselectDateButton.classList.add('d-none');
      this.dateSelectionCard.classList.remove('d-none');
      this.reservationContent.classList.remove('d-none');
    }
  }

  #updateHistory() {
    this.isSmallScreen = this.#isSmallScreen();
    if (this.isSmallScreen) {
      history.pushState({ isTimeSelected: this.isTimeSelected }, '');
    }
  }

  #syncWithHistory() {
    this.isSmallScreen = this.#isSmallScreen();
    if (this.isSmallScreen) {
      const initialState = history.state || { isTimeSelected: false };
      this.isTimeSelected = initialState.isTimeSelected;
      this.#toggleCardOnSmallScreen();
    }
  }
}

export default MobileView;
