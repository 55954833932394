import React from 'react';

import { ActionLinks } from './Components/ActionLinks';
import { ExamHistory } from './Components/ExamHistory';
import { ExamPageHeader } from './Components/ExamPageHeader';
import { ScheduledExams } from './Components/ScheduledExams';
import { UnscheduledExams } from './Components/UnscheduledExams';
import { MySessionsProvider, useMySessions } from './context';

/** @typedef {import('../../../types').GuardianDownloadUrls} GuardianDownloadUrls */
/** @typedef {import('../../../types').GuardianPlatform} GuardianPlatform */
/** @typedef {import('../../../types').Timestamp} Timestamp */

/**
 * @typedef MySessionsProps
 * @property {string} guardianExtensionUrl
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {boolean} isSupportedPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {number} advanceCancellationReasonId
 * @property {number[]} cancellationRequiredIds
 * @property {{ isSchedulingAllowed: boolean, isMoneyOwed: boolean }} currentUser
 * @property {boolean} isValidUserAgent
 * @property {boolean} isUCardNeeded
 * @property {boolean} shouldOpenExamFulfilledModal
 * @property {boolean} shouldOpenBrowserDownloadModal
 * @property {boolean} isDevelopmentEnv
 * @property {string} studentOrderPath
 * @property {string} studentReservationUrl
 * @property {string} selectStudentExamsPath
 * @property {string} testItOutUrl
 * @property {string} faqUrl
 * @property {boolean} isUcardCreateable
 * @property {boolean} isSchedulingAllowed
 * @property {boolean} isGuardianExtensionEnabledForAny
 * @property {boolean} isESPrechecksEnabledForAny
 * @property {boolean} isLoggedFromLms
 * @property {Timestamp} timestamp
 * @property {Reservation} [closestReservation] - TODO: Remove from `_my_sessions_props.json.jbuilder`?
 * @property {Reservation[]} reservations
 * @property {SessionHistory} sessionHistory
 * @property {User} currentUserData
 */

/**
 * MySessions component to display the user's sessions.
 *
 * @param {MySessionsProps} props
 * @returns {React.ReactElement}
 */
function MySessionsComponent(props) {
  const { reservations } = useMySessions();

  const scheduledReservations = reservations.filter(
    ({ status }) => status === 'scheduled' || status === 'running',
  );
  const unScheduledReservations = reservations.filter(
    ({ status }) => status === 'pending',
  );

  return (
    <div className="my-sessions-v2">
      <ExamPageHeader selectStudentExamsPath={props.selectStudentExamsPath} />
      <ActionLinks {...props} />
      <ScheduledExams {...props} reservations={scheduledReservations} />
      <UnscheduledExams {...props} reservations={unScheduledReservations} />
      <ExamHistory sessionHistory={props.sessionHistory} />
    </div>
  );
}

/**
 * MySessions component wrapped with MySessionsProvider.
 *
 * @param {MySessionsProps} props
 * @returns {React.ReactElement}
 */
export default function MySessions(props) {
  return (
    <MySessionsProvider initialReservations={props.reservations}>
      <MySessionsComponent {...props} />
    </MySessionsProvider>
  );
}
