import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';

import PrechecksBodyCamera from './CardSections/PrechecksBodyCamera';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksBodyTitle from './CardSections/PrechecksBodyTitle';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import PrechecksFooterButtonGroup from './CardSections/PrechecksFooterButtonGroup';

const OTHER_RESOURCES = 'other_resources';

/**
 * @typedef GuidedLaunchResourceCheckProps
 * @property {string} id
 * @property {string} icon
 * @property {string} iconPrefix
 * @property {() => void} handleNext - Function to transition to the next step.
 * @property {ResourceSelectionHandler} handleResourceSelection - Object containing data and functions for resource selection.
 * @property {SendEventFunction} sendEvent
 * @property {() => void} streamWebcam - Function to stream webcam to a video element.
 * @property {PrecheckData} precheckData
 */

/**
 * @param {GuidedLaunchResourceCheckProps} props
 * @returns {React.ReactElement}
 */
const GuidedLaunchResourceCheck = ({
  id,
  icon,
  handleNext,
  handleResourceSelection,
  sendEvent,
  streamWebcam,
  precheckData,
  iconPrefix,
}) => {
  const resourcesImg = precheckData.images.resourcesImg;
  const checkableResources = precheckData.checkableResources;
  const [roomScanPictureTaken, setRoomScanPictureTaken] = useState(false);
  const [btnText, setBtnText] = useState(
    polyglot.t('prechecks_guidedlaunch_resource_check_next_resource_button'),
  );
  const canvasRef = useRef();
  const videoRef = useRef();
  const height = 360;
  const width = 480;
  const [currentResourceIndex, setCurrentResourceIndex] = useState(0);
  const { selectedResourceCategories, selectedResources } =
    handleResourceSelection;
  const nonCheckableResourceCategories = ['other', 'software', 'web'];
  let accumulator_index = 0;

  const checkedResources = Object.entries(selectedResourceCategories).reduce(
    (accumulator, [resource_category, category_value], index) => {
      if (
        category_value &&
        nonCheckableResourceCategories.indexOf(resource_category) < 0
      ) {
        if (
          resource_category === OTHER_RESOURCES &&
          selectedResources[OTHER_RESOURCES]
        ) {
          accumulator[accumulator_index] = _.camelCase(OTHER_RESOURCES);
          accumulator_index += 1;
        }

        Object.entries(selectedResources).forEach(
          ([resource_name, resource_value]) => {
            if (resource_value) {
              Object.entries(checkableResources).forEach(
                ([checkable_category, checkable_resources]) => {
                  let camelCasedCheckableCategory =
                    _.camelCase(checkable_category);
                  if (
                    checkable_resources.includes(resource_name) &&
                    Object.values(accumulator).indexOf(
                      camelCasedCheckableCategory,
                    ) < 0
                  ) {
                    accumulator[accumulator_index] =
                      camelCasedCheckableCategory;
                    accumulator_index += 1;
                  }
                },
              );
            }
          },
        );
      }

      return accumulator;
    },
    {},
  );
  const lastResource =
    checkedResources[Math.max(...Object.keys(checkedResources))];
  const resourceObjects = {
    books: {
      name: 'books',
      title: polyglot.t('prechecks_guidedlaunch_resource_check_books_title'),
      text: polyglot.t('prechecks_guidedlaunch_resource_check_books_text'),
      sendLoadEvent: () => sendEvent('Event::BookCheckLoaded'),
      sendCompletedEvent: () => sendEvent('Event::BookCheckCompleted'),
    },
    calculators: {
      name: 'calculators',
      title: polyglot.t(
        'prechecks_guidedlaunch_resource_check_calculators_title',
      ),
      text: polyglot.t(
        'prechecks_guidedlaunch_resource_check_calculators_text',
      ),
      sendLoadEvent: () => sendEvent('Event::CalculatorCheckLoaded'),
      sendCompletedEvent: () => sendEvent('Event::CalculatorCheckCompleted'),
    },
    notes: {
      name: 'notes',
      title: polyglot.t('prechecks_guidedlaunch_resource_check_notes_title'),
      text: polyglot.t('prechecks_guidedlaunch_resource_check_notes_text'),
      sendLoadEvent: () => sendEvent('Event::NoteCheckLoaded'),
      sendCompletedEvent: () => sendEvent('Event::NoteCheckCompleted'),
    },
    noteTakingSurface: {
      name: 'noteTakingSurface',
      title: polyglot.t(
        'prechecks_guidedlaunch_resource_check_scratch_paper_title',
      ),
      text: polyglot.t(
        'prechecks_guidedlaunch_resource_check_scratch_paper_text',
      ),
      sendLoadEvent: () => sendEvent('Event::ScratchPaperCheckLoaded'),
      sendCompletedEvent: () => sendEvent('Event::ScratchPaperCheckCompleted'),
    },
    // NOTE: Other resources are added as plain text.
    otherResources: {
      name: _.camelCase(OTHER_RESOURCES),
      title: polyglot.t(
        'prechecks_guidedlaunch_resource_check_other_resources_title',
      ),
      text: polyglot.t(
        'prechecks_guidedlaunch_resource_check_other_resources_text',
      ),
      sendLoadEvent: () => sendEvent('Event::OtherResourcesCheckLoaded'),
      sendCompletedEvent: () =>
        sendEvent('Event::OtherResourcesCheckCompleted'),
    },
  };
  const [currentResource, setCurrentResource] = useState(
    resourceObjects[checkedResources[currentResourceIndex]],
  );

  const loadCurrentResource = () => currentResource.sendLoadEvent();
  const completeCurrentResource = () => currentResource.sendCompletedEvent();

  useEffect(() => {
    if (!currentResource) {
      handleNext();
    }
    streamWebcam(videoRef.current);
  }, []);

  useEffect(() => {
    if (currentResource) {
      loadCurrentResource();
    }
  }, [currentResource]);

  const nextStep = () => {
    completeCurrentResource();
    handleNext();
  };

  const nextResource = () => {
    completeCurrentResource();
    setCurrentResourceIndex((prevState) => {
      const nextIndex = prevState + 1;
      setCurrentResource(resourceObjects[checkedResources[nextIndex]]);
      return nextIndex;
    });
    setBtnText((prevBtnText) => {
      if (checkedResources[currentResourceIndex + 1] === lastResource) {
        return polyglot.t('prechecks_guidedlaunch_resource_check_next_button');
      } else {
        return prevBtnText;
      }
    });
  };

  const handleBack = () => {
    setCurrentResourceIndex((prevState) => {
      const prevIndex = prevState - 1;
      setCurrentResource(resourceObjects[checkedResources[prevIndex]]);
      return prevIndex;
    });
  };

  const handleBtnClick = () => {
    currentResource.name === lastResource ? nextStep() : nextResource();
  };

  const handleBackBtnClick = () => {
    handleBack();
  };

  const buttonObjects = [
    {
      buttonText: polyglot.t(
        'prechecks_guidedlaunch_select_resources_back_button',
      ),
      handleClick: handleBackBtnClick,
      buttonStyleClass: 'btn-precheck-secondary',
    },
    {
      buttonText: btnText,
      handleClick: handleBtnClick,
      buttonStyleClass: 'btn-precheck-primary',
    },
  ];

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <div className="mt-2">
        <img src={resourcesImg.src} height={'200'} alt={resourcesImg.altText} />
      </div>
      <PrechecksBodyTitle
        title={currentResource?.title}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={currentResource?.text} />
      </div>
      {currentResource?.name === _.camelCase(OTHER_RESOURCES) && (
        <div className="text-left row my-3 precheck-card-body">
          <div className="col-md-12">
            <p
              className="gl-other-resources-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(precheckData.otherResources),
              }}
            />
          </div>
        </div>
      )}
      <PrechecksBodyCamera
        canvasRef={canvasRef}
        videoRef={videoRef}
        showCanvas={roomScanPictureTaken}
        showVideo={!roomScanPictureTaken}
        height={height}
        width={width}
        precheckData={precheckData}
        area-label="Webcam Stream"
      />
      {currentResourceIndex === 0 ? (
        <PrechecksFooterButton
          buttonText={polyglot.t(
            'prechecks_guidedlaunch_resource_check_next_resource_button',
          )}
          handleClick={handleBtnClick}
        />
      ) : (
        <PrechecksFooterButtonGroup buttonProps={buttonObjects} />
      )}
    </div>
  );
};

GuidedLaunchResourceCheck.defaultProps = {
  icon: 'fa-pen-alt',
  iconPrefix: 'fal',
  statusColor: 'primary',
  iconColor: 'primary',
};

export default GuidedLaunchResourceCheck;
