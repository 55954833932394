const loadPowerBIScript = (scriptLoaded) => {
  return new Promise((resolve, reject) => {
    if (scriptLoaded) {
      resolve(); // Script has already been loaded
      return;
    }

    const script = document.createElement('script');
    script.src =
      'https://cdnjs.cloudflare.com/ajax/libs/powerbi-client/2.18.6/powerbi.min.js';
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error('Failed to load Power BI script.'));
    };
    document.head.appendChild(script);
  });
};

export { loadPowerBIScript };
