import React from 'react';

import { ReservationListItem } from './ReservationListItem';

/**
 * @typedef ExamHistoryProps
 * @property {SessionHistory} sessionHistory
 */

/**
 * @param {ExamHistoryProps} props
 * @returns {React.ReactElement}
 */
export const ExamHistory = ({ sessionHistory }) => {
  if ((sessionHistory?.length ?? 0) === 0) {
    return <></>;
  }

  return (
    <div className="card border-0">
      <div className="card-header bg-white border">
        <div className="fs-5 font-weight-bold">
          {polyglot.t('my_sessions_v2_exam_history')}
        </div>
      </div>
      <ul className="list-group list-group-flush border">
        {sessionHistory.map((session, index) => (
          <li className="list-group-item" key={session.uuid}>
            <ReservationListItem
              key={index}
              reservation={session}
              category="history"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
