import { useEffect, useRef, useState } from 'react';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksHeaderIcon from './CardSections/PrechecksHeaderIcon';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksBodyCamera from './CardSections/PrechecksBodyCamera';
import PrechecksFooterButtonGroup from './CardSections/PrechecksFooterButtonGroup';

const IdStepCard = ({
  id,
  icon,
  iconColor,
  iconPrefix,
  backButtonText,
  handlePrevious,
  handleNext,
  title,
  sendEvent,
  streamWebcam,
  precheckData,
}) => {
  const userId = precheckData.user.id;
  const videoLayout = precheckData.fulfillment.videoLayout;
  const identificationImg = precheckData.images.identificationImg;
  const [pictureTaken, setPictureTaken] = useState(false);
  const [takeButtonDisabled, setTakeButtonDisabled] = useState(true);
  const [takeButtonText, setTakeButtonText] = useState(
    polyglot.t('prechecks_id_step_take_photo_button'),
  );
  const canvasRef = useRef();
  const videoRef = useRef();
  const height = videoLayout == 'legacy' ? 240 : 180;
  const width = 320;
  const titleText =
    pictureTaken == false
      ? polyglot.t('prechecks_profile_photo_text_1')
      : polyglot.t('prechecks_profile_photo_text_2');
  const idPhotoOutlineData = precheckData.assets.idPhotoOutline;
  const outlineHidden = pictureTaken == false ? false : true;
  const overlayBg = useRef();
  const overlayIcon = useRef();
  const overlayHidden = true;
  const overlayStyling = {
    backgroundColor: 'white',
    width: '320px',
    height: videoLayout == 'legacy' ? '240px' : '180px',
    left: '29.7%',
    border: 'gray solid 1px',
  };

  useEffect(() => {
    sendEvent('Event::IdentityVerification');
    streamWebcam(videoRef.current);
  }, []);

  const handleTakePhoto = () => {
    // If the video still isn't ready, do nothing (extra safeguard)
    if (
      !videoRef.current ||
      videoRef.current.readyState < HTMLMediaElement.HAVE_ENOUGH_DATA
    ) {
      return;
    }
    setTakeButtonDisabled(true);
    overlayBg.current.hidden = false;
    overlayIcon.current.hidden = false;
    setTimeout(() => (overlayBg.current.hidden = true), 800);
    setTimeout(() => (overlayIcon.current.hidden = true), 800);
    const context = canvasRef.current.getContext('2d');
    context.translate(0, 0);
    context.drawImage(videoRef.current, 0, 0, width, height);
    sendEvent('Event::IdCaptured');
    sendEvent(
      'Event::IdConfirmation',
      {
        createdById: userId,
        attachmentUrl: canvasRef.current.toDataURL('image/png'),
        // sending true means that the face is verified
        data: true,
      },
      'image_checks',
      (response) => {
        if (response.status === 201) {
          setPictureTaken(true);
          setTakeButtonText(polyglot.t('prechecks_id_step_retake_button'));
          setTakeButtonDisabled(false);
        }
      },
      (error) => {
        setTakeButtonDisabled(false);
      },
    );
  };

  const handleBackBtnClick = () => {
    sendEvent('Event::BackOnIdPhotoStep', {});
    handlePrevious();
  };

  const handleRetake = () => {
    setPictureTaken(false);
    setTakeButtonText(polyglot.t('prechecks_id_step_take_photo_button'));
    setTakeButtonDisabled(false);
  };

  const handleVideoCanPlay = () => {
    // The video is ready, so enable the button
    setTakeButtonDisabled(false);
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={identificationImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <PrechecksBodyText bodyText={titleText} />
      <div className="position-relative">
        <PrechecksBodyCamera
          canvasRef={canvasRef}
          videoRef={videoRef}
          showCanvas={pictureTaken}
          showVideo={!pictureTaken}
          height={height}
          width={width}
          precheckData={precheckData}
          onCanPlay={handleVideoCanPlay}
        />
        <div
          className="position-absolute fixed-top mt-2"
          hidden={outlineHidden}
        >
          <img
            src={idPhotoOutlineData.src}
            alt={idPhotoOutlineData.altText}
            height={videoLayout == 'legacy' ? '224' : '162'}
          />
        </div>
        <div
          className="position-absolute fixed-top"
          hidden={overlayHidden}
          ref={overlayBg}
          style={overlayStyling}
        ></div>
        <div
          className="position-absolute fixed-top mt-5 pt-5"
          hidden={overlayHidden}
          ref={overlayIcon}
        >
          <PrechecksHeaderIcon
            icon={icon}
            iconColor={iconColor}
            iconPrefix={iconPrefix}
          />
        </div>
      </div>
      <PrechecksFooterButtonGroup
        buttonProps={[
          {
            buttonText: backButtonText,
            handleClick: handleBackBtnClick,
            buttonStyleClass: 'btn-precheck-secondary',
          },
          {
            buttonText: takeButtonText,
            handleClick: !pictureTaken ? handleTakePhoto : handleRetake,
            disabled: takeButtonDisabled,
            buttonStyleClass: !pictureTaken
              ? 'btn-precheck-primary'
              : 'btn-precheck-secondary',
          },
          {
            buttonText: polyglot.t('prechecks_id_step_next_button'),
            handleClick: handleNext,
            disabled: !pictureTaken,
            buttonStyleClass: 'btn-precheck-primary',
          },
        ]}
      />
    </div>
  );
};

IdStepCard.defaultProps = {
  icon: 'fa-check-circle',
  iconPrefix: 'fal',
  statusColor: 'primary',
  iconColor: 'success',
  title: polyglot.t('prechecks_id_step_title'),
  backButtonText: polyglot.t('prechecks_id_step_back_button'),
};

export default IdStepCard;
