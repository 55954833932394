import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

/** @typedef {import('../../../types').Reservation} Reservation */

/**
 * @typedef MySessionsContextValue
 * @property {Reservation[]} reservations
 * @property {Dispatch<SetStateAction<Reservation[]>>} setReservations
 * @property {(reservationUuid: string) => void} startExam
 * @property {(reservationUuid: string) => void} cancelExam
 */

export const MySessionsContext = createContext(
  /** @type {MySessionsContextValue} */ undefined,
);

/**
 * MySessionsProvider component to provide the MySessionsContext value.
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {Reservation[]} props.initialReservations
 * @returns {React.ReactElement}
 */
export const MySessionsProvider = ({ children, initialReservations }) => {
  const [reservations, setReservations] = useState(initialReservations);

  /**
   * Updates the `reservations` state variable such that the reservation with
   * the specified uuid is marked as *started*.
   * @param {string} reservationUuid - The uuid of the reservation to start.
   * @returns {void}
   */
  const startExam = useCallback((reservationUuid) => {
    setReservations((prevReservations) =>
      prevReservations.map((reservation) =>
        reservation.uuid === reservationUuid
          ? {
              ...reservation,
              fulfillment: {
                ...reservation.fulfillment,
                isAccessible: true,
              },
            }
          : reservation,
      ),
    );
  }, []);

  /**
   * Updates the `reservations` state variable such that the reservation with
   * the specified uuid is marked as *cancelled*.
   * @param {string} reservationUuid - The uuid of the reservation to cancel.
   * @returns {void}
   */
  const cancelExam = useCallback((reservationUuid) => {
    setReservations((prevReservations) =>
      prevReservations.map((reservation) =>
        reservation.uuid === reservationUuid
          ? {
              ...reservation,
              status: 'cancelled',
            }
          : reservation,
      ),
    );
  }, []);

  useEffect(() => {
    window.cancelExam = function (reservationUuid) {
      cancelExam(reservationUuid);
    };

    return () => delete window.cancelExam;
  }, [cancelExam]);

  return (
    <MySessionsContext.Provider
      value={{ reservations, setReservations, startExam, cancelExam }}
    >
      {children}
    </MySessionsContext.Provider>
  );
};

/**
 * Custom hook to use the MySessionsContext.
 * @returns {MySessionsContextValue}
 */
export function useMySessions() {
  return useContext(MySessionsContext);
}
