import React from 'react';

import moment from 'moment';

// For now this component only supports showing time in 12-hour format with AM/PM
const INTERNAL_DATE_FORMAT = 'hh:mmA';

/**
 * @typedef {Object} TimePickerProps
 *
 * @property {string} [time] The initial time value
 * @property {string} [dateFormat] A format string you want to apply to the time before onChange is called
 * @property {(value: string) => void} onChange The function to call when the time changes
 */

/**
 *
 * @param {TimePickerProps} props The props for the component
 * @returns {React.ReactElement}
 */
export default function TimePicker({ time, dateFormat, onChange }) {
  const displayTime = moment(time ?? '', INTERNAL_DATE_FORMAT);
  const hour = displayTime.hours() % 12 || 12;
  const minute = displayTime.minutes();
  const period = displayTime.hours() >= 12 ? 'PM' : 'AM';

  const handleSelect = (h = 1, m = 0, p = 'AM') => {
    const hour = h.toString().padStart(2, '0');
    const minute = m.toString().padStart(2, '0');
    const displayTimeString = `${hour}:${minute}${p}`;
    const formattedTime = moment(
      displayTimeString,
      INTERNAL_DATE_FORMAT,
    ).format(dateFormat);
    onChange?.(formattedTime);
  };

  const handleHourChange = (e) => {
    handleSelect(e.target.value, minute, period);
  };

  const handleMinuteChange = (e) => {
    handleSelect(hour, e.target.value, period);
  };

  const handlePeriodChange = (value) => {
    handleSelect(hour, minute, value);
  };

  return (
    <div className="timepicker">
      <div
        className="d-flex align-content-center flex-wrap time-select-container"
        style={{ justifyContent: 'space-evenly' }}
      >
        <div className="d-flex">
          <select
            className="my-2 rounded-lg time-select"
            title="Select Hour"
            aria-label="Select Hour"
            onChange={handleHourChange}
            value={hour}
          >
            {[...Array(13).keys()].slice(1).map((h) => (
              <option key={h} value={h}>
                {h.toString().padStart(2, '0')}
              </option>
            ))}
          </select>

          <span className="p-2 h4">:</span>

          <select
            className="my-2 rounded-lg time-select"
            title="Select Minute"
            aria-label="Select Minute"
            onChange={handleMinuteChange}
            value={minute}
          >
            {[...Array(60).keys()].map((m) => (
              <option key={m} value={m}>
                {m.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
        </div>

        <fieldset
          className="d-flex align-items-center select-time-period"
          aria-label="Select time period"
        >
          <legend className="d-none">Time Period</legend>
          <div className="d-flex align-items-center">
            <input
              type="radio"
              id="timePeriodAM"
              name="timePeriod"
              value="AM"
              checked={period === 'AM'}
              onChange={() => handlePeriodChange('AM')}
              aria-labelledby="labelAM"
            />
            <label htmlFor="timePeriodAM" id="labelAM" className="p-1 mb-0 btn">
              AM
            </label>
          </div>
          <div className="d-flex align-items-center">
            <input
              type="radio"
              id="timePeriodPM"
              name="timePeriod"
              value="PM"
              checked={period === 'PM'}
              onChange={() => handlePeriodChange('PM')}
              aria-labelledby="labelPM"
            />
            <label htmlFor="timePeriodPM" id="labelPM" className="p-1 mb-0 btn">
              PM
            </label>
          </div>
        </fieldset>
      </div>
    </div>
  );
}
