import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import PrechecksHeaderGroup from '../CardSections/PrechecksHeaderGroup';
import WelcomeAccommodations from './WelcomeAccommodations';
import WelcomeAllowedResources from './WelcomeAllowedResources';

const WelcomeAccordionItem = ({
  name,
  header = '',
  active = false,
  children,
}) => {
  return (
    <AccordionItem
      className={`mb-3 p-3 ${
        active ? 'precheck-card-primary-border' : 'border border-grey'
      }`}
      style={{ minHeight: '3rem', borderRadius: '0.25rem' }}
      uuid={name}
    >
      <AccordionItemHeading className="text-left precheck-dark">
        <AccordionItemButton>
          <AccordionItemState>
            {({ expanded }) => (
              <>
                <i
                  className={`fas ${
                    expanded ? 'fa-caret-down' : 'fa-caret-right'
                  } fa-1x mr-2`}
                  aria-hidden="true"
                />{' '}
                {header}
              </>
            )}
          </AccordionItemState>
        </AccordionItemButton>
      </AccordionItemHeading>

      <AccordionItemPanel style={{ overflowY: 'auto', maxHeight: '30rem' }}>
        {children}
      </AccordionItemPanel>
    </AccordionItem>
  );
};

/**
 * @typedef WelcomeHeaderProps
 * @property {string} title
 * @property {PrecheckData} precheckData
 */

/**
 * @param {WelcomeHeaderProps} props
 * @returns {React.ReactElement}
 */
const WelcomeHeader = ({ title, precheckData }) => {
  const [openItem, setOpenItem] = useState();

  const deskImg = precheckData?.images?.deskImg ?? '';
  const institutionName = precheckData?.institution?.name ?? '';
  const examName = precheckData?.exam?.name ?? '';
  const courseName = precheckData?.exam?.courseName ?? '';
  const instructorName = precheckData?.exam?.instructorName ?? '';
  const isLivePlus = precheckData?.livePlus ?? false;
  const fullTitle =
    examName === ''
      ? title
      : polyglot.t('prechecks_welcome_title_with_exam_name', {
          name: examName,
        });
  const subtitle =
    courseName === '' && instructorName === ''
      ? polyglot.t('prechecks_welcome_subtitle_institution', {
          institution_name: institutionName,
        })
      : instructorName === ''
        ? polyglot.t('prechecks_welcome_subtitle_institution_and_course', {
            institution_name: institutionName,
            course_name: courseName,
          })
        : polyglot.t('prechecks_welcome_subtitle_full', {
            institution_name: institutionName,
            course_name: courseName,
            instructor_name: instructorName,
          });

  return (
    <>
      <PrechecksHeaderGroup
        title={fullTitle}
        subtitle={subtitle}
        image={deskImg}
      />

      <Accordion
        className="mb-4"
        allowZeroExpanded={true}
        onChange={(e) => setOpenItem(e.at(0))}
        preExpanded={['what_to_expect']}
      >
        <WelcomeAccordionItem
          header={polyglot.t('prechecks_welcome_what_to_expect_header')}
          name="what_to_expect"
          active={openItem === 'what_to_expect'}
        >
          <>
            <p className="mt-2 text-left">
              {polyglot.t('prechecks_welcome_what_to_expect_paragraph1')}{' '}
              {polyglot.t(
                isLivePlus
                  ? 'prechecks_welcome_what_to_expect_live_plus_instruction'
                  : 'prechecks_welcome_what_to_expect_record_plus_instruction',
              )}
            </p>

            <p
              className="text-left"
              dangerouslySetInnerHTML={{
                __html: polyglot.t(
                  'prechecks_welcome_what_to_expect_paragraph2',
                ),
              }}
            ></p>

            <ul className="list-unstyled ml-2 mb-0">
              <li className="d-flex">
                <div className="text-center" style={{ width: '2.5rem' }}>
                  <i
                    className="fal fa-camera-web fa-1x mx-2 precheck-dark"
                    aria-hidden="true"
                  />{' '}
                </div>
                <p className="flex-1">
                  {polyglot.t('prechecks_welcome_what_to_expect_instruction1')}
                </p>
              </li>
              {precheckData?.fulfillment?.isSecondCameraEnabled && (
                <li className="d-flex">
                  <div className="text-center" style={{ width: '2.5rem' }}>
                    <i
                      className="fal fa-mobile fa-1x mx-2 precheck-dark"
                      aria-hidden="true"
                    />{' '}
                  </div>
                  <p className="flex-1">
                    {polyglot.t(
                      'prechecks_welcome_what_to_expect_second_camera_instruction',
                    )}
                  </p>
                </li>
              )}
              <li className="d-flex">
                <div className="text-center" style={{ width: '2.5rem' }}>
                  <i
                    className="fal fa-user-circle fa-1x mx-2 precheck-dark"
                    aria-hidden="true"
                  />{' '}
                </div>
                <p className="flex-1">
                  {polyglot.t('prechecks_welcome_what_to_expect_instruction2')}
                </p>
              </li>
              <li className="d-flex">
                <div className="text-center" style={{ width: '2.5rem' }}>
                  <i
                    className="fal fa-watch-calculator fa-1x mx-2 precheck-dark"
                    aria-hidden="true"
                  />{' '}
                </div>
                <p className="flex-1">
                  {polyglot.t('prechecks_welcome_what_to_expect_instruction3')}
                </p>
              </li>
              <li className="d-flex">
                <div className="text-center" style={{ width: '2.5rem' }}>
                  <i
                    className="fal fa-id-card fa-1x mx-2 precheck-dark"
                    aria-hidden="true"
                  />{' '}
                </div>
                <p className="flex-1">
                  {polyglot.t('prechecks_welcome_what_to_expect_instruction4')}
                </p>
              </li>
            </ul>
          </>
        </WelcomeAccordionItem>

        <WelcomeAccordionItem
          header={polyglot.t('prechecks_welcome_allowed_resources_header')}
          name="allowed_resources"
          active={openItem === 'allowed_resources'}
        >
          <WelcomeAllowedResources
            allowedResources={precheckData?.allowedResources}
            noResourcesAllowed={precheckData?.noResourcesAllowed}
            otherResources={precheckData?.otherResources}
          />
        </WelcomeAccordionItem>

        <WelcomeAccordionItem
          header={polyglot.t('prechecks_welcome_accommodations_header')}
          name="accommodations"
          active={openItem === 'accommodations'}
        >
          <WelcomeAccommodations
            accommodations={precheckData?.accommodations}
            durationModifierAccommodation={
              precheckData?.durationModifierAccommodation
            }
          />
        </WelcomeAccordionItem>
      </Accordion>
    </>
  );
};

export default WelcomeHeader;
