import PropTypes from 'prop-types';
import isInstitution from '../../../src/incident_report_center/utils/isInstitution'
import Select2Institution from '../../Select2/Select2Institution';
import IncidentTypeFilter from './IncidentTypeFilter';
import Select2DataRegion from '../../Select2/Select2DataRegion';

class IncidentReportFilter extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      hasStatusFilterError: false
    }
  }

  componentDidMount() {
    this.initializeDatePicker();
    this.initializeStatusSelect();
    $('#select2-institution').on('change', (event) => {
      this.props.updateInstitutionId(event.target.value);
    });
  }

  initializeDatePicker() {
    if ($('.report-date-picker').length > 1) {
      $('.report-date-picker').datetimepicker({
        format: 'MM/DD/YYYY',
        ignoreReadonly: true,
        icons: {
          next: 'fa fa-arrow-right',
          previous: 'fa fa-arrow-left'
        }
      });
    }
  }

  initializeStatusSelect() {
    const $status = $(this.refs.statusSelect)
    $status.select2({theme: 'bootstrap4', width: 'style' });

    $status.on('change', (event) => {
      if ($(event.currentTarget).val() == null) {
        this.setState({ hasStatusFilterError: true})
        $status.select2('val', event.removed.id)

        // hide error after 3 seconds
        clearTimeout(window.statusFilterTimeout)
        window.statusFilterTimeout = setTimeout(() => {
          this.setState({ hasStatusFilterError: false })
        }, 3000)

        // prevent from change Filter State.
        return false
      }

      this.props.updateStatusFilterState($('#status').val())
    })
  }

  institutionFilters() {
    if (!isInstitution()) {
      return (
        <div className='row'>
          <div className='col-6 col-sm-4'>
            <div className='form-group' data-testid='select2-institution'>
              <Select2Institution />
            </div>
          </div>
          <div className='col-6 col-sm-4'>
            { this.statusFilter() }
          </div>
          <div className='col-6 col-sm-4'>
            { this.locationFilter() }
          </div>
        </div>
      )
    }
  }

  dataRegionFilter() {
    if (!isInstitution()) {
      const { blankMsg, incidentReports} = this.props;
      const dataRegionClass = blankMsg !== 'Click Search to Find Incident Reports.' && incidentReports.length <= 0 ? 'data-region-red-box' : '';
      return (
        <div className={`form-group ${dataRegionClass}`} data-testid='select2-data-region'>
          <Select2DataRegion 
            options={this.props.dataRegionOptions}
            updateDataRegion={this.props.updateDataRegion}
          />
        </div>
      )
    }
  }

  examFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='exam'>{ polyglot.t('exam') }</label>
        <input className='form-control' id='exam' type='text'
          placeholder={ polyglot.t('exam') }
          value={this.props.filterParams.exam}
          onChange={this.props.updateFilterState} />
      </div>
    )
  }

  departmentFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='department'>{ polyglot.t('department') }</label>
        <input className='form-control' id='department' type='text'
          placeholder={ polyglot.t('department') }
          value={this.props.filterParams.department}
          onChange={this.props.updateFilterState} />
      </div>
    )
  }

  incidentTypeFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='subtype'>
          { polyglot.t('academic_integrity_type') }
        </label>
        <IncidentTypeFilter
          updateSubtypeFilterState={this.props.updateSubtypeFilterState}
          filterParams={this.props.filterParams} />
      </div>
    )
  }

  orderFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='order'>{ polyglot.t('order') }</label>
        <select className='form-control' id='order' type='text'
          value={this.props.filterParams.order} onChange={this.props.updateFilterState}>
          <option value=''>{ polyglot.t('oldest_first') }</option>
          <option value='recent'>{ polyglot.t('most_recent') }</option>
          <option value='unsorted'>{ polyglot.t('unsorted') }</option>
        </select>
      </div>
    )
  }

  statusFilter() {
    const irc_ir = 'IncidentReportCenter::IncidentReport::'

    return (
      <div className='form-group'>
        <label htmlFor='status'>{ polyglot.t('status') }</label>
        <select className='form-control' id='status' type='text'
                defaultValue={['New', `${irc_ir}NewReportState`]}
                ref="statusSelect" multiple='multiple'
                onChange={this.props.updateFilterState}
                placeholder={
                  polyglot.t('new_reports_returned_if_none_selected')
                }>
          <option key='New' value={`${irc_ir}NewReportState`}>
            { polyglot.t('new') }
          </option>
          <option key='In Progress' value={`${irc_ir}InProgressReportState`}>
            { polyglot.t('in_progress') }
          </option>
          <option key='Internally Submitted For Approval' value={
                    `${irc_ir}InternallySubmittedForApprovalReportState`
                  }>
            { polyglot.t('internally_submitted_for_approval') }
          </option>
          <option key='Internally Rejected'
                  value={`${irc_ir}InternallyRejectedReportState`}>
            { polyglot.t('internally_rejected') }
          </option>
          <option key='Approved And Submitted To Institution'
                  value={
                    `${irc_ir}ApprovedAndSubmittedToInstitutionReportState`
                  }>
            { polyglot.t('approved_and_submitted_to_institution') }
          </option>
        </select>
        { this.state.hasStatusFilterError &&
          <span className="form-text mb-0">
            { polyglot.t('at_least_one_status') }
          </span>
        }
      </div>
    )
  }

  filterButtons() {
    return (
      <div className={`form-group mb-0`}>
        <span className='float-right'>
          <button className='btn btn-secondary mr-2' id='reset'
            onClick={this.props.submitFilterForm}>
            { polyglot.t('reset') }
          </button>
          <button className='btn btn-primary' id='submit'  data-testid="submit"
            onClick={this.props.submitFilterForm}>
            { polyglot.t('search') }
          </button>
        </span>
      </div>
    );
  }

  locationFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='location_id'>
          { polyglot.t('proctor_location') }
        </label>
        <select className='form-control' id='location_id' type='text'
          value={this.props.filterParams.location_id}
          onChange={this.props.updateFilterState}>
          <option value=''>{ polyglot.t('all') }</option>
          {
            this.props.proctorLocations.map((location) => {
              return (
                <option key={location.id} value={location.id}>
                  { location.name }
                </option>
              )
            })
          }
          <option value='other'>{ polyglot.t('other') }</option>
        </select>
      </div>
    )
  }

  acrhivedFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='archived'>{ polyglot.t('archived') }</label>
        <select className='form-control' id='archived'
          type='text' value={this.props.filterParams.archived}
          onChange={this.props.updateFilterState}>
          <option value={false}>{ polyglot.t('unarchived') }</option>
          <option value={true}>{ polyglot.t('archived') }</option>
          <option value='all'>{ polyglot.t('all') }</option>
        </select>
      </div>
    )
  }

  instructorFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='instructor'>{ polyglot.t('instructor') }</label>
        <input className='form-control' id='instructor' type='text'
          placeholder={ polyglot.t('instructor') }
          value={this.props.filterParams.instructor}
          onChange={this.props.updateFilterState} />
      </div>
    )
  }

  startDateFiler() {
    return (
      <div className='form-group'>
        <label htmlFor='startDate'>{ polyglot.t('start_date') }</label>
        <input readOnly className='form-control report-date-picker'
          id='startDate' type='text' value={this.props.filterParams.startDate}
          placeholder={ polyglot.t('date_text') }
          onBlur={this.props.updateFilterState} />
      </div>
    )
  }

  endDateFiler() {
    return (
      <div className='form-group'>
        <label htmlFor='endDate'>{ polyglot.t('end_date') }</label>
        <input readOnly className='form-control report-date-picker'
          id='endDate' type='text' value={this.props.filterParams.endDate}
          placeholder={ polyglot.t('date_text') }
          onBlur={this.props.updateFilterState} />
      </div>
    )
  }

  testTakerFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='testTaker'>{ polyglot.t('test_taker') }</label>
        <input className='form-control' id='testTaker' type='text'
          placeholder={ polyglot.t('test_taker_placeholder') }
          value={this.props.filterParams.testTaker}
          onChange={this.props.updateFilterState} />
      </div>
    )
  }

  courseNameFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='courseName'>{ polyglot.t('course_name') }</label>
        <input className='form-control' id='courseName' type='text'
               placeholder={ polyglot.t('course_name') }
               value={this.props.filterParams.courseName}
               onChange={this.props.updateFilterState} />
      </div>
    )
  }

  serviceTypeFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='serviceType'>{ polyglot.t('irc_service') }</label>
        <select className='form-control' id='serviceType' onChange={this.props.updateFilterState}>
          <option value=''>{ polyglot.t('all') }</option>
          {
            this.props.serviceTypes.map((service_type) => {
              return (
                <option key={service_type[0]} value={service_type[1]}>
                  { service_type[2] }
                </option>
              )
            })
          }
        </select>
      </div>
    )
  }

  render() {
    var params = this.props.filterParams;
    return (
      <div id='filter-content' data-testid='incident-report-filter'>
        <div className='card-body'>
          { this.institutionFilters() }
          <div className='row'>
            <div className='col-6 col-sm-4'>
              {this.testTakerFilter() }
            </div>
            <div className='col-6 col-sm-4'>
              { this.startDateFiler() }
            </div>
            <div className='col-6 col-sm-4'>
              { this.endDateFiler() }
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-sm-4'>
              { this.departmentFilter() }
            </div>
            <div className='col-6 col-sm-4'>
              { this.examFilter() }
            </div>
            <div className="col-md-4">
              { this.courseNameFilter() }
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-sm-4'>
              { this.instructorFilter() }
            </div>
            <div className="col-md-4">
              { this.incidentTypeFilter() }
            </div>
            <div className="col-md-4">
              { this.serviceTypeFilter() }
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-sm-4'>
              { this.acrhivedFilter() }
            </div>
            <div className="col-6 col-sm-4">
              { this.orderFilter() }
            </div>
            <div className="col-6 col-sm-4">
              { this.dataRegionFilter() }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 float-right">
              { this.filterButtons() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IncidentReportFilter.propTypes = {
    id: PropTypes.number.isRequired,
    incidentStatuses: PropTypes.array,
    updateFilterState: PropTypes.func.isRequired,
    updateStatusFilterState: PropTypes.func.isRequired,
    updateSubtypeFilterState: PropTypes.func.isRequired,
    updateInstitutionId: PropTypes.func.isRequired,
    proctorLocations: PropTypes.array,
    serviceTypes: PropTypes.array,
    submitFilterForm: PropTypes.func.isRequired,
    filterParams: PropTypes.object.isRequired
  }

export default IncidentReportFilter;
