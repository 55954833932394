import React from 'react';

import { ExamRulesModal } from '../Modals/ExamRulesModal';
import {
  CancelButton,
  RescheduleButton,
  ViewInCart,
} from './ReservationActions';

/**
 * @typedef {React.PropsWithChildren<{}>} ReservationListItemProps
 * @property {SessionHistoryItem | Reservation} reservation
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {string[]} cancellationRequiredIds
 * @property {boolean} isSupportedPlatform
 * @property {boolean} isDevelopmentEnv
 * @property {'history' | undefined} category
 */

/**
 * @param {React.PropsWithChildren<ReservationListItemProps>} props
 * @returns {React.ReactElement}
 */
export function ReservationListItem({
  reservation,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  isSupportedPlatform,
  isDevelopmentEnv,
  category,
  children,
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isHistory = category === 'history';

  const getExamDate = () => {
    if (isHistory) {
      return reservation.isFulfilled
        ? reservation.formattedExamDate
        : reservation.fulfillmentWindowClosingDate;
    } else {
      return reservation.fulfillment?.formattedDateDecorated;
    }
  };

  const getExamTime = () => {
    if (isHistory) {
      return reservation.isFulfilled
        ? reservation.formattedExamTime
        : reservation.fulfillmentWindowClosingTime;
    } else {
      return reservation.fulfillment?.startsAtTimeDecorated;
    }
  };

  const getHistoryStatus = () => {
    if (reservation.isFulfilled) {
      return polyglot.t('my_sessions_v2_reservation_list_session_fulfilled');
    } else {
      return polyglot.t('my_sessions_v2_reservation_list_exam_ended');
    }
  };

  const handleToggleExamRulesModal = () => {
    setIsModalOpen((previousState) => !previousState);
  };

  const renderScheduleButton = () => (
    <a
      className="font-weight-bold d-inline-flex d-md-none gap-medium"
      href={reservation.editStudentReservationPath}
    >
      {polyglot.t('my_sessions_v2_reservation_actions_schedule')}
    </a>
  );

  const renderRescheduleAndCancelButtons = () => (
    <div className="d-md-none d-inline-flex gap-medium text-md-left">
      <RescheduleButton
        reservation={reservation}
        isSupportedPlatform={isSupportedPlatform}
      />
      <CancelButton
        reservation={reservation}
        studentReservationUrl={studentReservationUrl}
        studentOrderPath={studentOrderPath}
        cancellationRequiredIds={cancellationRequiredIds}
        isSupportedPlatform={isSupportedPlatform}
      />
    </div>
  );

  const renderViewInCart = () => (
    <ViewInCart
      className="d-inline-flex d-md-none gap-medium"
      studentOrderPath={studentOrderPath}
      isSupportedPlatform={isSupportedPlatform}
    />
  );

  const renderReservationActions = () => {
    const hasFulfillmentUuid = !!reservation.fulfillment?.uuid;
    const isAccessible = reservation.fulfillment?.isAccessible;
    const isOnOpenOrder = reservation.isOnOpenOrder;
    const isHidden =
      !isDevelopmentEnv ||
      (reservation.fulfillment?.isAccessible ?? false) ||
      reservation.fulfillment?.ptc;

    if (hasFulfillmentUuid) {
      if (!isAccessible && !isOnOpenOrder && isHidden) {
        return renderRescheduleAndCancelButtons();
      }

      if (isOnOpenOrder && isHidden && !isSupportedPlatform) {
        return renderViewInCart();
      }

      return <></>;
    }

    if (isOnOpenOrder && !isSupportedPlatform) {
      return renderViewInCart();
    }

    if (!isSupportedPlatform) {
      return renderScheduleButton();
    }

    return <></>;
  };

  const isOnlineReservation =
    !reservation.fulfillment?.ptc ||
    !reservation.fulfillment?.ptcLocationAddress1;

  return (
    <div
      className="reservation-list-item"
      data-reservation-uuid={reservation.uuid}
    >
      <div className="row">
        <div className="col-md-6 align-self-center">
          <h5 className="card-title exam-name fs-5 font-weight-bold">
            {reservation.examName}
          </h5>
          <p className="card-text text-muted fs-6 institution-name">
            {reservation.institutionName}
          </p>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-4 align-self-center exam-datetime-container">
              <p
                className={`card-text mb-0 fs-6 ${getExamDate()?.trim() === '' && 'd-none'}`}
              >
                {getExamDate()}
              </p>
              <p
                className={`card-text fs-6 ${getExamTime()?.trim() === '' && 'd-none'}`}
              >
                {getExamTime()}
              </p>
            </div>
            <div
              className={`col-md-4 text-md-left align-self-center ${
                isHistory && !reservation.decoratedReservationStatus
                  ? ''
                  : 'badge-container'
              }`}
            >
              {!isHistory && (
                <>
                  {isOnlineReservation && (
                    <span
                      data-testid="online-badge"
                      className="badge badge-dark mr-1"
                    >
                      {polyglot.t('my_sessions_v2_reservation_list_online')}
                    </span>
                  )}
                  <span className="badge badge-dark">
                    {reservation.iteration.serviceLineDecorated}
                  </span>
                </>
              )}
              {isHistory && (
                <div className="fs-6">
                  {reservation.decoratedReservationStatus}
                </div>
              )}
            </div>
            <div
              className={`col-md-4 text-md-left align-self-center d-flex justify-content-between align-items-center d-md-block`}
            >
              {!isHistory && reservation.shouldShowExamRulesButton && (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleToggleExamRulesModal}
                >
                  {polyglot.t('my_sessions_v2_reservation_list_exam_rules')}
                </button>
              )}
              <div
                className={`${reservation.isOnOpenOrder ? 'd-md-none' : 'd-md-block'}`}
              >
                {!isHistory && renderReservationActions()}
              </div>

              {isHistory && (
                <p className="card-text mb-0 fs-6">{getHistoryStatus()}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isHistory && (
        <div className="row mt-2">
          <div className="col-lg-12 align-self-center">{children}</div>
        </div>
      )}

      {reservation.shouldShowExamRulesButton && (
        <ExamRulesModal
          reservation={reservation}
          show={isModalOpen}
          handleCloseModal={handleToggleExamRulesModal}
        />
      )}
    </div>
  );
}
